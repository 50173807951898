




import Vue from "vue";
import SettingContent from "./partials/SettingContent.vue";
export default Vue.extend({
  components: { SettingContent },
  data : () => {
    return {
      language: "en",
    }
  },
  methods: {
    changeLanguage(language: string) {
      this.language = language;
    }
  },
  async created() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang as string;
    }
  }
});
